footer {
    min-height: $size-footer-height;
    @extend .bg-color-footer;
    @extend .flex;
    @extend .flex-wrap;
    @extend .items-center;

    .footer-wrapper {
        width: 100%;
        padding: 0 5%;
        @extend .flex;
        @extend .flex-wrap;
        @extend .items-center;
        @extend .justify-between;
        @extend .text-color-grey;
        @extend .font-footer;

        .logo-copyright {
            margin-right: 10%;
            .copyright {
                margin-top: 15px;
                font-size: 13px;
                color: $color-white;
                opacity: 0.68;
            }
        }

        .menu-footer {
            a.link {
                font-family: $font-family-2;
                font-size: 16px;
                color: $color-white;
                transition: 0.3s;
                text-transform: uppercase;
                &:hover {
                    color: $color-1;
                    transition: 0.3s;
                }
            }
        }

        .mediaweb {
            a {
                .text {
                    p {
                        font-size: 12px !important;
                        color: $color-3;
                    }
                }
            }
        }
        @media screen and (max-width: 1500px){
            .logo-copyright {
                margin-right: 0%;
            }
            @media screen and (max-width: 1250px){
                flex-direction: column;
                align-items: initial;
                .menu-footer {
                    padding: 50px 0;
                }
                .mediaweb {
                    padding-bottom: 50px;
                }
                @media screen and (max-width: $size-xs-max){
                    .menu-footer {
                        flex-direction: column;
                        a.link.mr7 {
                            margin: 0 0 25px 0;
                        }
                    }
                }
            }
        }
    }
    @media screen and (max-width: 1250px){
        min-height: 400px;
        @media screen and (max-width: $size-xs-max){
            min-height: 525px;
        }
    }
}
