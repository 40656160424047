$input_height: 50px;
$input_back_color: $bg-color-body;
$input_text_color: $text-color;
$input_text_size: $font-size-20;

$input_placeholder_color: $color-text-gris;
$input_placeholder_size: $font-size-18;

$input_label_color: $text-muted;
$input_label_size: $font-size-18;

$input_border_color: $color-2;
$input_border_radius: $round-corner-radius; //based on variable
$input_border_size: 1px;
$input_border_style: solid;

$label_checkbox_border_color: $text-muted;
$input_checked_color: $color-2;

$textarea_height: 200px;

$message_errors_text_color: $color-error;
$message_errors_border_color: $color-error;
$message_errors_border_size: 5px;
$message_errors_border_radius: 5px;
$message_errors_background_color: hsla(0, 100%, 55%, 0.7);
$message_success_text_color: $color-success;
$message_success_border_color: lighten($color-success, 20%);
$message_success_border_size: 5px;
$message_success_border_radius: 5px;
$message_success_background_color: $bg-color-body;

form {
    input.form-control, textarea.form-control {
        height: 82px;
        color: $color-black;
        font-size: $input_text_size;
        background-color: #00000017;
        border: none;
        border-radius: 0;
    }
    input::placeholder, textarea::placeholder{
        font-weight: normal !important;
        font-size: $font-size-16 !important;
        color: #899596 !important;
        text-transform: initial;
    }
    textarea::placeholder {
        padding: 50px 5px;
    }
    label {
        color: $input_label_color;
        font-size: $input_label_size;
    }

    .title {
        text-transform: uppercase;
        color: $input_text_color;
        font-weight: bold;
        margin-top: 1em;
    }
    .center {
        text-align: center;
        margin-left: auto;
        margin-right: auto;
    }
    .clear-both {
        clear: both;
    }
    .form-group {
        margin-bottom: -15px;
    }

    input[type="radio"]:checked {
        accent-color: $color-2;
    }

    .radios, .checkboxes {
        width: 100%;
        margin: 20px 0 20px 0;
        display: flex;
        align-items: center;
        justify-content: space-between;
        input[type="radio"], input[type="checkbox"] {
            position: absolute;
            visibility: hidden;
            &:checked+label:before {
                background-color: $input_checked_color;
            }
        }
        input[type="radio"] {
            &+label:before {
                border-radius: 100%;
            }
        }
        label {
            display: inline-block;
            cursor: pointer;
            color: $input_label_color;
            position: relative;
            padding: 6px 5px 6px 35px;
            font-size: $input_text_size;
            transition: all .3s ease;
            &:before {
                content: "";
                display: inline-block;
                width: $input_text_size;
                height: $input_text_size;
                position: absolute;
                left: 0px;
                background: none;
                border: 2px solid $label_checkbox_border_color;
            }
        }
    }
    .radios {
        justify-content: space-between;
    }
    textarea {
        //margin: 2em 0;
        height: $textarea_height !important;
    }
    .buttons {
        display: flex;
        //justify-content: space-between;
        flex-wrap: wrap;
        margin: 10px 0;
        padding: 0;
    }

}

.has-error {
    .form-control {
        color: $message_errors_text_color;
    }
}
.help-block {
    min-height: 15px;
}
.with-errors {
    li {
        line-height: 1;
    }
}



.messages {
    padding: 30px;
    margin-bottom: 2em;
    &.errors, &.success {
        p {
            font-size: $font-size-20;
            line-height: 1;
            margin: 0
        }
    }
    &.errors {
        background-color: $message_errors_background_color;
        border-radius: $message_errors_border_radius;
        border: $message_errors_border_size solid $message_errors_border_color;
        color: $message_errors_text_color;
        p {
            color: $message_errors_text_color;
        }
    }
    &.success {
        background-color: $message_success_background_color;
        border-radius: $message_success_border_radius;
        border: $message_success_border_size solid $message_success_border_color;
        color: $message_success_text_color;
        p {
            color: $message_success_text_color;
        }
    }
}
