/* ==========================================================================
   Animation triggered with intersection observer
   ========================================================================== */

   [data-scroll='opacity'] {
    //Hidden
    opacity: 0;
    transition: all 1s ease;

    //Visible
    &.is-active {
        opacity: 1;
    }
}

[data-scroll='fromBottomSlow'] {
    //Hidden
    opacity: 0;
    transform: translateY(60px);
    transition: all 1.8s ease;

    //Visible
    &.is-active {
        opacity: 1;
        transform: translateY(0);
    }
}

[data-scroll='fromBottom'] {
    //Hidden
    opacity: 0;
    transform: translateY(60px);
    transition: all 1.2s ease;

    //Visible
    &.is-active {
        opacity: 1;
        transform: translateY(0);
    }
}

[data-scroll='fromBottomFast'] {
    //Hidden
    opacity: 0;
    transform: translateY(60px);
    transition: all 0.6s ease;

    //Visible
    &.is-active {
        opacity: 1;
        transform: translateY(0);
    }
}

[data-scroll='fromTopSlow'] {
    //Hidden
    opacity: 0;
    transform: translateY(-60px);
    transition: all 1.8s ease;

    //Visible
    &.is-active {
        opacity: 1;
        transform: translateY(0);
    }
}

[data-scroll='fromTop'] {
    //Hidden
    opacity: 0;
    transform: translateY(-60px);
    transition: all 1.2s ease;

    //Visible
    &.is-active {
        opacity: 1;
        transform: translateY(0);
    }
}

[data-scroll='fromTopFast'] {
    //Hidden
    opacity: 0;
    transform: translateY(-60px);
    transition: all 0.6s ease;

    //Visible
    &.is-active {
        opacity: 1;
        transform: translateY(0);
    }
}

[data-scroll='fromRightSlow'] {
    //Hidden
    opacity: 0;
    transform: translateX(60px);
    transition: all 1.8s ease;

    //Visible
    &.is-active {
        opacity: 1;
        transform: translateX(0);
    }
}

[data-scroll='fromRight'] {
    //Hidden
    opacity: 0;
    transform: translateX(60px);
    transition: all 1.2s ease;

    //Visible
    &.is-active {
        opacity: 1;
        transform: translateX(0);
    }
}

[data-scroll='fromRightFast'] {
    //Hidden
    opacity: 0;
    transform: translateX(60px);
    transition: all 0.6s ease;

    //Visible
    &.is-active {
        opacity: 1;
        transform: translateX(0);
    }
}

[data-scroll='fromLeftSlow'] {
    //Hidden
    opacity: 0;
    transform: translateX(-60px);
    transition: all 1.8s ease;

    //Visible
    &.is-active {
        opacity: 1;
        transform: translateX(0);
    }
}

[data-scroll='fromLeft'] {
    //Hidden
    opacity: 0;
    transform: translateX(-60px);
    transition: all 1.2s ease;

    //Visible
    &.is-active {
        opacity: 1;
        transform: translateX(0);
    }
}

[data-scroll='fromLeftFast'] {
    //Hidden
    opacity: 0;
    transform: translateX(-60px);
    transition: all 0.6s ease;

    //Visible
    &.is-active {
        opacity: 1;
        transform: translateX(0);
    }
}