.flex {
	display: -webkit-box;      /* OLD - iOS 6-, Safari 3.1-6 */
	display: -webkit-flex;
	display: -moz-flex;
	display: -ms-flex;
	display: -o-flex;
	display: flex;
}
.flex-column  {
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
}
.flex-row  {
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-webkit-flex-direction: row;
	-ms-flex-direction: row;
	flex-direction: row;
}
.flex-wrap    {
	-webkit-flex-wrap: wrap;
	-moz-flex-wrap: wrap;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
}

.items-start    {
	-webkit-align-items: flex-start;
	align-items: flex-start;
}
.items-end      {
	-webkit-align-items: flex-end;
	align-items: flex-end;
}
.items-center   {
	-webkit-align-items: center;
	align-items: center;
}
.items-baseline {
	-webkit-align-items: baseline;
	align-items: baseline;
}
.items-stretch  {
	-webkit-align-items: stretch;
	align-items: stretch;
}

.self-start    {
	-webkit-align-self: flex-start; /* add prefixed version */
	-ms-flex-item-align: start; /* add prefixed version */
	align-self: flex-start;
}
.self-end      {
	-webkit-align-self: flex-end; /* add prefixed version */
	-ms-flex-item-align: end; /* add prefixed version */
	align-self: flex-end;
}
.self-center   {
	-webkit-align-self: center; /* add prefixed version */
	-ms-flex-item-align: center;
	align-self: center
}
.self-baseline { align-self: baseline }
.self-stretch  { align-self: stretch }

.justify-start   {
	-webkit-justify-content: flex-start;
	-moz-justify-content: flex-start;
	-ms-justify-content: flex-start;
	justify-content: flex-start;
}
.justify-end  {
	-webkit-justify-content: flex-end;
	-moz-justify-content: flex-end;
	-ms-justify-content: flex-end;
	justify-content: flex-end;
}
.justify-center  {
	-webkit-justify-content: center;
	-moz-justify-content:center;
	-ms-justify-content:center;
	justify-content: center;
}
.justify-between {
	-webkit-justify-content: space-between;
	-moz-justify-content: space-between;
	-ms-justify-content: space-between;
	justify-content: space-between;
}
.justify-around  {
	-webkit-justify-content: space-around;
	-moz-justify-content: space-around;
	-ms-justify-content: space-around;
	justify-content: space-around;
}
.justify-evenly  {
	-webkit-justify-content: space-evenly;
	-moz-justify-content: space-evenly;
	-ms-justify-content: space-evenly;
	justify-content: space-evenly;
}

.content-start   { align-content: flex-start }
.content-end     { align-content: flex-end }
.content-center  { align-content: center }
.content-between { align-content: space-between }
.content-around  { align-content: space-around }
.content-stretch { align-content: stretch }

/* 1. Fix for Chrome 44 bug. https://code.google.com/p/chromium/issues/detail?id=506893 */
.flex-auto {
  flex: 1 1 auto;
  min-width: 0; /* 1 */
  min-height: 0; /* 1 */
}
.flex-none { flex: none }

.order-0 { order: 0 }
.order-1 { order: 1 }
.order-2 { order: 2 }
.order-3 { order: 3 }
.order-last { order: 99999 }
