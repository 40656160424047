.module-header {
    position: relative;

    &--image {}

    &--image-mobile {}

    &--caption {
        position: absolute;
        top: 78%;
        width: 100%;
        padding: 0 5%;
        transform: translateY(-50%);

        &--title {
            width: 100%;
            font-size: $font-size-46;
            font-weight: 500;
            color: $color-white;
            text-transform: initial;
            padding-bottom: 20px;
        }
        &--subtitle {
            width: 100%;
            font-size: $font-size-20;
            font-weight: normal;
            color: $color-white;
            line-height: 1.5;
            width: 53%;
        }
    }
}
@media screen and (max-width: 1585px) {
    .module-header {
        &--caption {
            &--subtitle {
                width: 63%;
            }
        }
    }
    @media screen and (max-width: 1485px) {
        .module-header {
            &--caption {
                top: 75%;
            }
        }
        @media screen and (max-width: 1350px) {
            .module-header {
                &--caption {
                    top: 72%;
                    &--subtitle {
                        width: 74%;
                    }
                }
            }
            @media screen and (max-width: 1024px) {
                .module-header {
                    &--caption {
                        top: 72%;
                        &--title {
                            font-size: 2rem;
                        }
                        &--subtitle {
                            width: 80%;
                            font-size: 0.9rem;
                        }
                    }
                }
                @media screen and (max-width: $size-sm-max) {
                    .module-header {
                        &--caption {
                            top: 70%;
                            &--subtitle {
                                width: 100%;
                            }
                        }
                    }
                    @media screen and (max-width: 375px) {
                        .module-header {
                            &--caption {
                                top: 70%;
                                &--title {
                                    font-size: 1.6rem;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

.module-header.realisations {
    .module-header--caption {
        top: 85%;
    }
}

.module-header-coordonnees {
    position: relative;
    .module-header--caption {
        top: 50%;
    }
    .section-coordonnees.desktop {
        background: #fff;
        padding: 50px;
        position: absolute;
        width: 1090px;
        border-top-left-radius: 20px;
        right: 0;
        bottom: 0;
        .left-side {
            width: 80%;
            margin-right: 50px;
            a {
                font-weight: bold;
                font-size: $font-size-24;
                color: $color-black;
            }
        }
        .right-side {
            width: 50%;
            a {
                font-weight: normal;
                font-size: $font-size-18;
                color: $color-black;
            }
        }
        h4 {
            font-weight: normal;
            font-size: $font-size-24;
            color: $color-black;
            &.title {
                font-weight: 600;
            }
        }
        p {
            font-size: $font-size-18;
        }
    }
    @media screen and (max-width: 1650px) {
        .module-header--caption {
            top: 40%;
        }
        @media screen and (max-width: 1460px) {
            .module-header--caption {
                top: 55%;
            }   
            .section-coordonnees.desktop {
                display: none;
            }

            @media screen and (max-width: $size-md-max) {
                .module-header--caption {
                    left: 5%;
                }
                @media screen and (max-width: $size-sm-max) {
                    .module-header--caption {
                        top: 65%;
                    }
                    @media screen and (max-width: $size-xs-max) {
                        .module-header--caption {
                            top: 75%;
                            h3 br {
                                display: none;
                            }
                        }
                    }
                }
            }
        }
    }
}