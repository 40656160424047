// IMPORT ADDONS
@import '../vendors/bootstrap/css/bootstrap';
//@import '../vendors/font-awesome/font-awesome';

/* purgecss start ignore */
@import '../vendors/owl-carousel2/owl.carousel';
@import '../vendors/magnific-popup/magnific-popup';
// @import '../vendors/cube-portfolio/cubeportfolio';
@import 'styles/alertify';
/* purgecss end ignore */

// IMPORT CUSTOMIZATION & RESET (order is important)
@import 'core/reset';
@import 'variables';
@import 'core/html';
@import 'utilities/functions';

// *******************
// Load basic classes (besoin de rester ici à la fin)
@import 'styles/fonts'; // utility classes for fonts
@import 'utilities/spacing'; // utility classes for spacing
@import 'utilities/flex'; // utility classes for flex box
@import 'utilities/responsive';
@import 'utilities/animation';
//@import 'utilities/wrapper';

@import 'styles/colors'; // utility classes for colors
@import 'styles/hover';
@import 'styles/typography';
@import 'styles/buttons';

/* GENERAL TEMPLATE */
body {
    background-color: $bg-color-body;

    // to fix parallax bug on iphone...
    .parallax {
        background-attachment: fixed;
        @media screen and (max-width: $size-xs-max) {
            background-attachment: scroll;
        }
    }
}

/** Add this class to any anchor tag so it clear the fixed menu height (used in forms) */
.jumptarget::before {
    content: "";
    display: block;
    height: $size-nav-height; /* fixed header height*/
    margin: -$size-nav-height 0 0; /* negative fixed header height */
}

/** Adds a shadow to the div element **/
.shadow {
    position: relative;

    &::after {
        content: '';
        z-index: 0;
        width: 100%;
        top: 100%;
        height: 40px;
        position: absolute;
        background: linear-gradient(to bottom, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
    }
}

h2.visibilityCheck, h3.visibilityCheck, h4.visibilityCheck, h5.visibilityCheck, p.visibilityCheck {
    transform: translateY(100px);
    transition: 1s;
    opacity: 0;
    &.isVisible {
        transform: translateY(0);
        transition: 1s;
        opacity: 1;
    }
}

img.visibilityCheck {
    transition: 1s;
    opacity: 0;
    &.isVisible {
        transition: 1s;
        opacity: 1;
    }
}

/*****************************************************/
/* Menu (to use with /modules/module-menu.php)
/* Or /modules/module-menu-centre.php
/* Or /modules/module-menu-etage.php
 */
/*****************************************************/
@import 'modules/menu';
// @import 'menu-centre';
// @import 'menu-etage';


/*****************************************************/
/* Footer (to use with /modules/module-footer.php)
/*****************************************************/
@import 'modules/footer';

/*****************************************************/
/* Sections (to use with /sections/section-*-*.php)
/*****************************************************/
//@import 'sections/section-header-page';

//@import 'sections/section-text-image';
//@import 'sections/section-temoignages';

@import 'sections/section-bursts';

/*****************************************************/
/* Modules (to use with /modules/module-*-*.php)
/*****************************************************/
//@import 'modules/module-slideshow';
@import 'modules/module-slider-light';
@import 'modules/module-header';
@import 'modules/module-soumission';



a.btn-link {
    width: 220px;
    height: 65px;
    background: $color-1;
    border-radius: 10px;
    border: 3px solid $color-1;
    transition: 0.3s;
    h5 {    
        font-weight: normal;
        font-size: 18px;
        color: $color-white;
        text-transform: uppercase;
        letter-spacing: 3.6px;
        transition: 0.3s;
    }
    &:hover {
        background: transparent;
        border: 3px solid $color-white;
        transition: 0.3s;
    }
    @media screen and (max-width: 1024px) {
        h5 {
            font-size: 16px;
        }
        @media screen and (max-width: $size-xs-max) {
            width: 200px;
        }
    }
}






/*******************************************************************************
* ACCUEIL
*******************************************************************************/

#section-bloc-image {
    padding: 100px 0 75px 0;
    .left-side {
        width: 49%;
        background: $color-black;
        padding: 135px 65px 135px 5%;
        position: absolute;
        h3 {
            font-weight: 500;
            font-size: $font-size-30;
            color: $color-white;
            line-height: 1.3;
            text-transform: initial;
            padding-bottom: 40px;
        }
        p {
            color: #EDEDED;
        }
        
    }
    .right-side {
        margin-left: auto;
    }
    @media screen and (max-width: 1875px){
        .right-side {
            width: 75%;
        }
        @media screen and (max-width: 1680px){
            .left-side {
                padding: 125px 65px 125px 5%;
            }
            @media screen and (max-width: 1550px){
                .left-side {
                    padding: 100px 65px 100px 5%;
                    h3 {
                        font-size: 1.3rem;
                    }
                }
                @media screen and (max-width: 1460px){
                    .left-side {
                        padding: 75px 65px 75px 5%;
                    }
                    @media screen and (max-width: 1410px){
                        .left-side {
                            h3 {
                                font-size: 1.15rem;
                            }
                            p {
                                font-size: 16px;
                            }
                        }
                        @media screen and (max-width: $size-md-max){
                            flex-direction: column-reverse;
                            padding: 100px 5% 75px 5%;
                            .left-side {
                                width: 75%;
                                padding: 75px 50px;
                                margin: auto;
                                position: initial;
                                h3 {
                                    font-size: 1.5rem;
                                }
                            }
                            .right-side {
                                width: 75%;
                                margin: auto;
                            }
                            @media screen and (max-width: $size-sm-max){
                                padding: 100px 5% 0 5%;
                                .left-side, .right-side {
                                    width: 100%;
                                }
                                @media screen and (max-width: $size-xs-max){
                                    .left-side {
                                        padding: 75px 5%;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

#section-territoire {
    padding: 65px 5% 100px 5%;
    text-align: center;
    h4 {
        font-weight: 500;
        font-size: $font-size-30;
        color: $color-black;
        padding-bottom: 30px;
    }
    h3 {
        font-weight: 500;
        font-size: $font-size-50;
        color: $color-black;
        text-transform: initial;
        span {
            font-weight: normal;
            color: $color-1;
            font-size: smaller;
            padding: 0 20px;
        }
    }
    @media screen and (max-width: $size-xs-max) {
        padding: 65px 5% 75px 5%;
        h3 {
            font-size: 1.8rem;
            span {
                padding: 0 15px;
            }
        }
        @media screen and (max-width: 375px) {
            h3 {
                font-size: 1.7rem;
            }
        }
    }
}

#section-images {
    padding: 0 5%;
    .section-images.accordion {
        font-size: 0;
        height: 100%;
        .image.accordion__item {
            font-size: 1rem;
            display: inline-block;
            vertical-align: top;
            height: 100%;
            min-height: 720px;
            box-sizing: border-box;
            position: relative;
            outline: none;
            background-size: cover;
            // border: 1px solid black;
            background-position: center;
            background-repeat: no-repeat;
            overflow: hidden;
            width: 100%;
            &:nth-child(1) {
                background-image: url("../images/accueil_bloc4_slide01.jpg");
            }
            &:nth-child(2) {
                background-image: url("../images/accueil_bloc4_slide02.jpg");
            }

            div.txt-box {
                position: absolute;
                top: 75%;
                left: 50%;
                transform: translate(-50% , -50%);
                div.wrapper {
                    position: relative;
                }
                h3 {
                    color: $color-3;
                    font-weight: normal;
                    font-size: 90px;
                    transition: .5s;
                    text-align: center;
                    &.overlay {
                        opacity: 0;
                        visibility: hidden;
                        transition: .5s;
                        position: absolute;
                        top: 0;
                        width: 0;
                    }
                }
            }
            &.active {
                div.txt-box {
                    h3 {
                        font-size: 70px;
                        opacity: 0;
                        text-transform: initial;
                        visibility: hidden;
                        transition: .5s;
                        &.overlay {
                            transition: .5s;
                            opacity: 1;
                            width: auto;
                            transform: translate(-50% , 0);
                            visibility: visible;
                        }
                    }
                }
            }
        }
    }
    @media screen and (max-width: 1024px) {
        .section-images.accordion {
            .image.accordion__item.active {
                div.txt-box h3 {
                    font-size: 50px;
                }
            }
        }
    }
}

#section-services {
    padding: 115px 5%;
    .bloc {
        background: #EDEDED;
        border-radius: 16px;
        width: 398px;
        height: 337px;
        .icon {
            padding-bottom: 35px;
        }
        .text {
            h4 {
                font-family: $font-family-2;
                font-size: $font-size-20;
                color: $color-black;
            }
        }
    }
    @media screen and (max-width: 1500px) {
        .bloc {
            width: 400px;
            height: 300px;
        }
        @media screen and (max-width: 1200px) {
            .bloc {
                width: 300px;
                height: 250px;
                .icon {
                    width: 40%;
                }
            }   
            @media screen and (max-width: $size-sm-max) {
                flex-wrap: wrap;
                .bloc {
                    width: 48%;
                    height: 250px;
                    margin: 5px;
                }
                @media screen and (max-width: $size-xs-max) {
                    .bloc {
                        width: 100%;
                        height: 300px;
                        margin: 0 0 15px 0;
                        .icon {
                            width: 40%;
                            display: flex;
                            justify-content: center;
                        }
                        .text {
                            h4 {
                                font-size: 20px;
                            }
                        }
                    }
                }
            }
        }
    }
}

#section-title-logo {
    padding: 15px 5% 100px 5%;
    .title {
        text-align: center;
        padding-bottom: 100px;
        h3, a {
            font-weight: normal;
            font-size: $font-size-38;
            color: $color-black;
            text-transform: initial;
            text-decoration: underline;
        }
    }
    @media screen and (max-width: $size-xs-max) {
        padding: 15px 5% 75px 5%;
        .section-logo {
            flex-direction: column;
            .logo.mx8 {
                margin: 35px 0;
            }
        }
    }
}

/*******************************************************************************
* SERVICES
*******************************************************************************/

#section-image-text {
    padding: 90px 5% 0 5%;
    .left-side {
        width: 50%;
        &.pic01 {
            background: url(../images/services_bloc01.jpg) no-repeat;
            background-size: cover;
            background-position: center;
            @media screen and (max-width: $size-xs-max) {
                background: url(../images/services_bloc01_m.jpg) no-repeat;
                background-size: cover;
            }
        }
        &.pic03 {
            background: url(../images/services_bloc03.jpg) no-repeat;
            background-size: cover;
            background-position: center;
            @media screen and (max-width: $size-xs-max) {
                background: url(../images/services_bloc03_m.jpg) no-repeat;
                background-size: cover;
            }
        }
    }
    .right-side {
        width: 50%;
        background: #00000017;
        border-bottom-left-radius: 20px;
        border-top-left-radius: 20px;
        margin-left: 55px;
        padding: 75px 50px;
        h3 {
            font-weight: 500;
            font-size: $font-size-30;
            color: $color-black;
            text-transform: initial;
            padding-bottom: 28px;
        }
        .section-list {
            padding: 30px 0 20px 0;
            .bloc {
                .text {
                    h5 {
                        font-weight: bold;
                        font-size: $font-size-18;
                        color: $color-black;
                    }
                }
            }
        }
        a.btn-link {
            margin-top: 65px;
            h5 {
                transition: 0.3s;
            }
            &:hover {
                border: 3px solid $color-black;
                h5 {
                    color: $color-black;
                    transition: 0.3s;
                }
            }
        }
    }
    @media screen and (max-width: 1250px) {
        flex-direction: column;
        .left-side {
            width: 70%;
            margin: auto;
        }
        .right-side {
            width: 70%;
            border-radius: 20px;
            margin: 15px auto 0 auto;
        }
        @media screen and (max-width: $size-sm-max) {
            .left-side, .right-side {
                width: 100%;
            }
            @media screen and (max-width: $size-xs-max) {
                .right-side {
                    padding: 60px 25px;
                    .section-list {
                        .bloc {
                            .text {
                                width: 100%;
                                h5 {
                                    font-size: 1rem;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

#section-text-image {
    padding: 90px 0 0 0;
    .left-side {
        width: 50%;
        background: #00000017;
        margin-right: 55px;
        border-bottom-right-radius: 20px;
        border-top-right-radius: 20px;
        padding: 75px 5%;
        h3 {
            font-weight: 500;
            font-size: $font-size-30;
            color: $color-black;
            text-transform: initial;
            padding-bottom: 28px;
        }
        .section-list {
            padding: 20px 0 30px 0;
            .bloc {
                .text {
                    h5 {
                        font-weight: bold;
                        font-size: $font-size-18;
                        color: $color-black;
                        span {
                            font-weight: normal;
                        }
                    }
                }
            }
        }
        a.btn-link {
            margin-top: 65px;
            h5 {
                transition: 0.3s;
            }
            &:hover {
                border: 3px solid $color-black;
                h5 {
                    color: $color-black;
                    transition: 0.3s;
                }
            }
            &.long {
                margin-top: 50px;
                width: 270px;
            }
        }
    }
    .right-side {
        width: 50%;
        &.pic02 {
            background: url(../images/services_bloc02.jpg) no-repeat;
            background-size: cover;
            background-position: center;
            @media screen and (max-width: $size-xs-max) {
                background: url(../images/services_bloc02_m.jpg) no-repeat;
                background-size: cover;
            }
        }
        &.pic04 {
            background: url(../images/services_bloc04.jpg) no-repeat;
            background-size: cover;
            background-position: center;
            @media screen and (max-width: $size-xs-max) {
                background: url(../images/services_bloc04_m.jpg) no-repeat;
                background-size: cover;
            }
        }
    }
    @media screen and (max-width: 1250px) {
        flex-direction: column-reverse;
        padding: 90px 5% 0 5%;
        .left-side {
            width: 70%;
            border-radius: 20px;
            padding: 75px 50px;
            margin: 15px auto 0 auto;
        }
       .right-side {
            width: 70%;
            margin: auto;
        }
        @media screen and (max-width: $size-sm-max) {
            .left-side, .right-side {
                width: 100%;
            }
            @media screen and (max-width: $size-xs-max) {
                .left-side {
                    padding: 60px 25px;
                    .section-list {
                        .bloc {
                            .text {
                                width: 100%;
                                h5 {
                                    font-size: 1rem;
                                }
                            }
                        }
                    }
                    a.btn-link.long {
                        width: 230px;
                    }
                }
            }
        }
    }
}

#section-image {
    padding: 90px 5%;
}

/*******************************************************************************
* RÉALISATIONS
*******************************************************************************/

#section-realisations {
    padding: 75px 5%;
    .realisations {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-gap: 15px;
        @media screen and (max-width: $size-md-max){
            grid-template-columns: repeat(2, 1fr);
            grid-gap: 25px;
            @media screen and (max-width: $size-xs-max){
                grid-template-columns: repeat(1, 1fr);     
            }
        }
        a.item {
            padding-bottom: 75px;
            .img-responsive {
                border-radius: 10px;
            }
            @media screen and (max-width: $size-md-max){
                padding-bottom: 50px;
                @media screen and (max-width: $size-xs-max){
                    padding-bottom: 35px;
                }
            }
        }
    }
}

/*******************************************************************************
* COORDONNÉES
*******************************************************************************/

#section-coordonnees.mobile {
    background: #fff;
    padding: 75px 5%;
    display: none;
    .left-side {
        width: 80%;
        margin-right: 50px;
        a {
            font-weight: bold;
            font-size: $font-size-24;
            color: $color-black;
        }
    }
    .right-side {
        width: 50%;
        a {
            font-weight: normal;
            font-size: $font-size-18;
            color: $color-black;
        }
    }
    h4 {
        font-weight: normal;
        font-size: $font-size-24;
        color: $color-black;
        &.title {
            font-weight: 600;
        }
    }
    p {
        font-size: $font-size-18;
    }
    @media screen and (max-width: 1460px) {
        display: flex;
        padding: 75px 5% 0 5%;
        @media screen and (max-width: $size-xs-max) {
            flex-direction: column;
            padding: 60px 5% 0 5%;
            .left-side {
                width: 100%;
                margin: 0 0 25px 0;
                .icon {
                    display: none;
                }
            }
            .right-side {
                width: 100%;
                .tel {
                    padding-bottom: 25px;
                }
            }
        }
    }
}

#section-form {
    padding: 100px 0 75px 0;
    margin: 0 5%;
    border-bottom: 1px solid #000;
    overflow: hidden;
    @media screen and (max-width: $size-xs-max) {
        .col-sm-6.pl0, .col-sm-6.pr0 {
            padding: 0;
        }
        .buttons {
            justify-content: center;
            .btn-submit {
                min-width: initial !important;
            }
            .btn.mr5 {
    margin: 0 0 20px 0;
}
        }
    }
}


#section-heures-map {
    padding: 75px 5%;
    .left-side {
        width: 50%;
        h3 {
            font-weight: 500;
            font-size: $font-size-46;
            color: $color-black;
            text-transform: initial;
            padding-bottom: 15px;
        }
        h4 {
            font-weight: bold;
            font-size: $font-size-18;
            line-height: 2.3;
            color: $color-black;
        }
        h5 {
            font-weight: normal;
            font-size: $font-size-18;
            line-height: 2.3;
            color: $color-black;
        }
        .jour {
            width: 50%;
        }
    }
    .right-side {
        width: 85%;
    }
    @media screen and (max-width: 1450px) {
        .left-side {
            margin-right: 5%;
        }
        @media screen and (max-width: $size-md-max) {
            padding: 75px 5%;
            .right-side {
                width: 50%;
            }
            @media screen and (max-width: $size-sm-max) {
                flex-direction: column;
                .left-side {
                    width: 100%;
                    margin: 0 0 50px 0;
                    .jour {
                        width: 28%;
                    }
                }
                .right-side {
                    width: 100%;
                }
                @media screen and (max-width: $size-xs-max) {
                    .left-side {
                        .jour {
                            width: 45%;
                        }
                    }
                }
            }
        }
    }
}

#content {
    h3#firstHeading, {
        color: $color-1;
    }
    p a {
        color: $color-black;
    }
}





/*******************************************************************************
* MOBILE OVERRIDES FOR ALL SECTIONS
*******************************************************************************/

@media screen and (max-width: $size-md-max) {
    

}

@media screen and (max-width: $size-sm-max) {
    .popup-box img {
        width: 100% !important;
    }
}

@media screen and (max-width: $size-xs-max) {
    

}
