.btn {
    background: $color-1;
    border-radius: 10px;
    border: 3px solid $color-1;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 160px;
    height: 65px;

    font-weight: normal;
    font-size: $font-size-18;
    color: $color-white;
    text-transform: uppercase;
    letter-spacing: 3.6px;
    transition: 0.3s;
    
    &:hover {
        background: transparent;
        border: 3px solid $color-1;
        color: #124248;
        transition: 0.3s;
    }
}

.btn.filedoc {
    width: 215px;
}