.slideshow-wrap {
    position: relative;
    .caption {
        position: absolute;
        top: 60%;
        left: 0;
        width: 100%;
        padding-left: 5%;
        z-index: $z-index-slide-caption;
        h2 {
            font-weight: 500;
            font-size: $font-size-46;
            color: $color-white;
            padding-bottom: 10px;
        }
        p {
            color: $color-white;
            padding-bottom: 65px;
            width: 53%;
        }
        @media screen and (max-width: 1650px) {
            top: 55%;
            @media screen and (max-width: 1555px) {
                p {
                    width: 66%;
                }
                @media screen and (max-width: 1410px) {
                    top: 50%;
                    p {
                        width: 72%;
                    }
                    @media screen and (max-width: 1220px) {
                        top: 44%;
                        p {
                            width: 84%;
                        }
                        @media screen and (max-width: 1024px) {
                            padding: 0 5%;
                            top: 50%;
                            p {
                                width: 100%;
                                font-size: 16px;
                                padding-bottom: 35px;
                            }
                            @media screen and (max-width: $size-sm-max) {
                                position: initial;
                                padding: 75px 5% 0 5%;   
                                h2, p {
                                    color: $color-black;
                                }
                                h2 {
                                    padding-bottom: 20px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    .icon-fb {
        position: absolute;
        right: 0;
        top: 0;
        padding-right: 5%;
        padding-top: 10%;
        z-index: 10;
        @media screen and (max-width: 1550px) {
            top: 25px;
            @media screen and (max-width: 1350px) {
                top: 50px;
                @media screen and (max-width: $size-xs-max) {
                    top: 85px;
                }
            }
        }
    }


    .slideshow, .slideshow-main {
        width: 100%;
        overflow: hidden;
        position: relative;
        .placeholder {
            width: 100vw;
            height: auto;
            display: block;
            @media screen and (max-width: $size-slider-breakpoint){
                height: calc(100vw / 480 * 600);
            }
        }
        &.mobile {
            display: none;
        }
        .slide {
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            opacity: 0;
            width: 100vw;
            overflow: hidden;
            position: absolute;
            backface-visibility: hidden;
            transition: 1.5s;

            &_wrapper {
                position: relative;
                .layer-inner {
                    left: 0;
                    right: 0;
                    bottom: 20%;
                    padding: 20px;
                    position: absolute;
                    background: rgba(0,0,0, 0.5);

                    h2 {
                        font-weight: 900;
                        text-align: center;
                        font-size: $font-size-46;
                        color: $color-white;
                        @media screen and (max-width: $size-sm-max) {
                            font-size: 2rem;
                        }
                    }
                }
            }

          img {
            width: 100vw!important;
            height: auto;
            display: block;
            position: relative;
          }

            &.transiting {
                left: 0;
                right: 0;
                opacity: 1;
                transition: 1.5s;
          }
          &.active {
              right: 0;
                left: 0;
              z-index: 10;
                opacity: 1;
                transition: 1.5s;
          }
        }
        @media screen and (max-width: 480px) {
            &.mobile {
                display: block;
            }
            &.ecran {
                display: none;
            }
        }
    }
}
